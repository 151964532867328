body {
  padding: 0;
  margin: 0;
}

#root {
  //position: fixed;
  width: 100%;
}

.vkuiPanel .vkuiPanel__in,
.vkuiPanel::after {
  overflow: hidden;
}

@media (min-width: 500px) {
  .vkuiPanel .vkuiPanel__in,
  .vkuiPanel::after {
    background: #eeeeee !important;
  }
}

.preload-img {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.link-unstyled {
  color: inherit !important;
  text-decoration: none !important;
}

.vkuiCell--dragging {
  background-color: transparent !important;
  box-shadow: none;
}

.text-center {
  text-align: center;
  text-align: -webkit-center;
}
